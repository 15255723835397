import React, { useState } from "react";
import { toast } from "react-toastify";
import loginImage from "../images/image-5.png";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useMyContext } from "../context/MyContext";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { isLoading, setIsLoading } = useMyContext();
  const navigate = useNavigate();
  const Maily = sessionStorage.getItem("resetEmail");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Regular expression to check if the password meets the required criteria
    const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    // Check if the password meets the criteria
    if (!passwordPattern.test(password)) {
      toast.error(
        "Password must contain at least 8 characters including one uppercase letter, one lowercase letter, and one number."
      );
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      // Passwords don't match, show error toast
      toast.error("Passwords do not match");
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/forgot/${Maily}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Password: password,
            ConfirmPassword: confirmPassword,
          }),
        }
      );

      const responseData = await response.json();

      if (response.ok && responseData.Access && responseData.Updated) {
        // Password reset successful
        toast.success("Password reset successful");
        navigate("/login");
        // Handle redirect or any other action upon successful password reset
      } else {
        // Handle password reset failure
        toast.error(responseData.Error);
      }
    } catch (error) {
      // Handle error
      toast.error("An error occurred while resetting the password");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex py-4 my-3 flex-col lg:flex-row min-h-screen px-8 justify-between mt-24 lg:mt-0 md:mt-0">
      {/* Left side with image */}
      <div className="lg:w-1/ hidden md:hidden lg:block">
        <img
          src={loginImage}
          alt="Login"
          className="object-cover w-full h-full"
        />
      </div>

      {/* Right side with login form */}

      <div className="lg:w-1/2 flex items-center justify-center p-8 mt-8">
        <div className="max-w-md w-full">
          <h2 className="text-3xl font-extrabold text-black uppercase">
            Forgot Password?
          </h2>

          {/* Password Change Form */}
          <form className="mt-4" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-black"
              >
                Password
              </label>
              <input
                type="password"
                className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Create Password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-black"
              >
                Confirm Password
              </label>
              <input
                type="password"
                className={`mt-1 p-2 w-full border rounded-md focus:outline-none ${
                  password !== confirmPassword ? "border-red-500" : ""
                }`}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </div>
            <button
              type="submit"
              className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
