import React from 'react';
import VisitorHeader from '../component/vistorsheader';
import Footer from "../component/footer";

const RefundPolicy = () => {
  return (
    <div>
      <VisitorHeader />
      <div className='py-4 lg:py-8 px-2 mt-20 max-w-5xl mx-auto bg-white shadow-lg rounded-lg'>
        <h2 className="text-3xl lg:text-4xl font-medium text-[#2d2d2d] mb-6 text-center">REFUND POLICY</h2>
        <p className="text-lg"><strong className="text-lg">Effective Date:</strong> 20th January 2024</p>
        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">Overview:</h3>
        <p className="text-lg">At Buzzy.ng, we are committed to providing a positive and satisfying experience for our users. This Refund Policy outlines the terms and conditions regarding refunds for transactions made on our platform.</p>
        
        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">1. Eligibility for Refunds:</h3>
        <p className="text-lg"><strong className="text-lg">a. Digital Products and Services:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Due to the nature of digital products and services offered on Buzzy.ng, all transactions involving the purchase of digital goods, advertising services, or other virtual items are non-refundable.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Physical Products:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Refunds for physical products are subject to the terms and conditions set by the individual sellers. Buzzy.ng facilitates transactions, but the refund policies for physical products are determined by the sellers themselves.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">2. Refund Process:</h3>
        <p className="text-lg"><strong className="text-lg">a. Digital Products and Services:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">In the event of a technical issue or a failure to deliver a purchased digital product or service, users are encouraged to contact our support team at support@buzzy.ng for assistance.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Physical Products:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Users seeking a refund for a physical product should reach out directly to the seller through the Buzzy.ng platform. The seller's contact information can typically be found on their profile or product page.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">3. Unauthorized Transactions:</h3>
        <p className="text-lg">If you believe an unauthorized transaction has taken place on your account, please contact our support team immediately at support@buzzy.ng to investigate and resolve the issue.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">4. Seller Responsibilities:</h3>
        <p className="text-lg">Sellers on Buzzy.ng are responsible for clearly communicating their refund policies to buyers. Buyers are encouraged to review the seller's terms before making a purchase.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">5. Dispute Resolution:</h3>
        <p className="text-lg"><strong className="text-lg">a. Digital Products and Services:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">In the case of disputes related to digital products or services, our support team will investigate and mediate to reach a fair resolution.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Physical Products:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Disputes related to physical products should be resolved directly between the buyer and the seller. If a resolution cannot be reached, users may contact our support team for assistance.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">6. Changes to Refund Policy:</h3>
        <p className="text-lg">Buzzy.ng reserves the right to modify or update this Refund Policy at any time. Users will be notified of any changes, and the updated policy will be effective immediately upon posting.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">Contact Information:</h3>
        <p className="text-lg">If you have questions or concerns about our Refund Policy, please contact us at <a href="mailto:support@buzzy.ng">support@buzzy.ng</a></p>

        <p className="text-lg mt-4">Thank you for choosing Buzzy.ng.</p>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
