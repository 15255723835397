import React from 'react';
import VisitorHeader from '../component/vistorsheader';
import Footer from "../component/footer";

const PrivacyPolicy = () => {
  return (
    <div>
      <VisitorHeader />
      <div className='py-4 lg:py-8 px-4 mt-20 max-w-5xl mx-auto bg-white shadow-lg rounded-lg'>
        <h2 className="text-3xl lg:text-4xl font-medium text-[#2d2d2d] mb-6 text-center">PRIVACY POLICY</h2>
        <p className="text-lg"><strong className="text-lg">Effective Date:</strong> 20th January 2024</p>
        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">Overview:</h3>
        <p className="text-lg">Welcome to Buzzy.ng, a social media marketing app designed to connect users, advertisers, and content creators in an interactive and engaging environment. At Buzzy.ng, safeguarding your privacy is a top priority. This Privacy Policy outlines how we collect, use, and protect your personal information when you use our app.</p>
        
        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">1. Information We Collect:</h3>
        <p className="text-lg"><strong className="text-lg">a. Account Information:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">When you create a Buzzy.ng account, we collect necessary information, including your name, email address, and other details to personalize your experience.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Payment Details:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">If you engage in financial transactions on Buzzy.ng, we collect payment information to facilitate secure transactions. This information is encrypted and stored securely.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">c. User-Generated Content:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">We may collect and store content you generate, upload, or share on Buzzy.ng, such as posts, comments, and other user-generated content.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">d. Device Information:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Information about the devices you use to access Buzzy.ng, such as device type, operating system, and unique device identifiers.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">2. How We Use Your Information:</h3>
        <p className="text-lg"><strong className="text-lg">a. Personalization:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Your information is used to personalize your Buzzy.ng experience, tailoring content recommendations, advertisements, and features.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Communication:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">We use your contact information to send important updates, notifications, and promotional messages related to Buzzy.ng. You can manage communication preferences in your account settings.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">c. Transaction Processing:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Payment information is used to process transactions, provide receipts, and manage billing-related activities.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">d. Security and Authentication:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Your information is utilized to authenticate your identity, secure your account, and protect against unauthorized access.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">3. Information Sharing:</h3>
        <p className="text-lg"><strong className="text-lg">a. Third-Party Collaborations:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">We may share your information with trusted third-party partners, such as advertisers and marketers, to provide personalized content and targeted advertisements.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Legal Compliance:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Information may be disclosed to comply with legal obligations, respond to legal requests, or protect our rights, privacy, safety, or property.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">4. Your Choices:</h3>
        <p className="text-lg"><strong className="text-lg">a. Account Settings:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Manage your account settings, privacy preferences, and communication preferences through the Buzzy.ng app.</li>
        </ul>
        <p className="text-lg"><strong className="text-lg">b. Opt-Out:</strong></p>
        <ul className="list-disc pl-6">
          <li className="text-lg">Opt-out of promotional communications by following the unsubscribe instructions provided in the messages.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">5. Security Measures:</h3>
        <p className="text-lg">We implement industry-standard security measures to protect your information from unauthorized access, disclosure, alteration, and destruction.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">6. Updates to Privacy Policy:</h3>
        <p className="text-lg">This Privacy Policy may be updated periodically to reflect changes in our practices. We encourage you to review this policy regularly.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">Contact Information:</h3>
        <p className="text-lg">If you have questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@buzzy.ng">support@buzzy.ng</a></p>

        <p className="text-lg mt-4">Thank you for choosing Buzzy.ng.</p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
