import React from "react";
import { FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Bottom = () => {
  return (
    <div className="bg-blue-900 relative py-5 md:py-10 text-center px-3 md:px-0">
      <div className=" md:w-[50%] mx-auto md:px-[5px]">
        <h1 className="text-[26px] md:text-[36px] font-semibold text-center text-[#F1F1F5]">
          Promote and Generate Daily Income Using Your Social Media Profile.
        </h1>
        <p className="md:text-base md:px-32 font-thin py-2 md:py-4 text-center text-[#F1F1F5]">
          Join countless individuals leveraging Buzzy.ng for advertising and
          consistent social media earnings. Start for free today.
        </p>
        <div className="flex justify-center items-center my-3 space-x-4">
          <Link to="/register">
            <button className="px-4 py-2 text-base font-thin rounded-md border-2 border-[#F1F1F5] text-white group hover:bg-[#405AC6] hover:border-[#405AC6] transition-all">
              Get Started
            </button>
          </Link>

          <Link to="/login">
            <button className="flex items-center justify-evenly px-4 py-2 rounded-md bg-[#F1F1F5] text-[#405AC6] group hover:bg-[#405AC6] hover:text-white hover:border-[#405AC6] transition-all">
              <FaUser className="text-lg pt-1 mr-1" />
              Login
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Bottom;
