import React, { lazy, Suspense, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./styles/tailwind.css";
import ClipLoader from "react-spinners/ClipLoader";
import { ClimbingBoxLoader } from "react-spinners";

import { useMyContext } from "./context/MyContext";
import NotFound from "./pages/NotFound";
import FacebookAd from "./earnComponent/facebookAd";
import MarketItemPage from "./component/DashLayout/DashPages/MarketItemPage";
import UploadForm from "./component/DashLayout/DashPages/UploadForm";
import DashMyProdut from "./component/DashLayout/DashPages/DashMyProdut";
import Password from "./pages/Password";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import RefundPolicy from "./pages/RefundPolicy";
import Terms from "./pages/Terms";
import AboutPage from "./pages/About";
import Register1 from "./register-component/Register1";
import Register2 from "./register-component/Register2";
import Register3 from "./register-component/Register3";
import Register4 from "./register-component/Register4";
import MarketItemPage2 from "./component/subComponent/MarketitemsPage2";
import ReferralList from "./component/DashLayout/DashPages/ReferralList";
import Airtime from "./component/DashLayout/DashPages/Airtime";
import DataPurchase from "./component/DashLayout/DashPages/DataPurchase";
import Chat2 from "./component/DashLayout/DashPages/Chat2";
import AdForm3 from "./component/DashLayout/DashPages/AdForn3";
import TaskDelivery from "./component/DashLayout/DashPages/TaskDelivery";
import TaskDeliveryEng from "./component/DashLayout/DashPages/TaskDeliveryEngagment";
import AdformFake from "./component/DashLayout/DashPages/AdFormFake";
import PayTv from "./component/DashLayout/DashPages/Bills/PayTv";
import Electricity from "./component/DashLayout/DashPages/Bills/Electricity";
import ExamWaec from "./component/DashLayout/DashPages/Bills/Exam/WAEC";
import ExamJamb from "./component/DashLayout/DashPages/Bills/Exam/Jamb";
import EULA from "./pages/EULA";
// import DashChat from "./component/DashLayout/DashPages/WhatsappAd/DashChat";

const Home = lazy(() => import("./pages/Home"));
const Earn = lazy(() => import("./pages/Earn"));
const Advert = lazy(() => import("./pages/Advert"));
const Marketplace = lazy(() => import("./pages/MarketPlace"));
const Support = lazy(() => import("./pages/Support"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const Register = lazy(() => import("./pages/Register"));
const BecomeMember = lazy(() =>
  import("./component/DashLayout/DashPages/BecomeMember")
);
const Dash = lazy(() => import("./pages/UserDashboard"));
const TaskPage = lazy(() => import("./pages/Task"));
const DashEarn = lazy(() =>
  import("./component/DashLayout/DashPages/DashEarn")
);
const Advertise1 = lazy(() =>
  import("./component/DashLayout/DashPages/DashAdvert")
);
const Advertise2 = lazy(() =>
  import("./component/DashLayout/DashPages/DashAdvert2")
);
const DashMarket = lazy(() =>
  import("./component/DashLayout/DashPages/DashMarket")
);
const Tasks = lazy(() => import("./component/DashLayout/DashPages/Tasks"));
const Settings = lazy(() => import("./component/DashLayout/DashPages/setting"));
const DashOrder = lazy(() =>
  import("./component/DashLayout/DashPages/Myorder")
);
const DashWallet = lazy(() =>
  import("./component/DashLayout/DashPages/Wallet")
);
const DashChat = lazy(() => import("./component/DashLayout/DashPages/Chat"));
const DashNotification = lazy(() =>
  import("./component/DashLayout/DashPages/Notification")
);

const AdForm = lazy(() => import("./component/DashLayout/DashPages/AdForm"));
const AdForm2 = lazy(() => import("./component/DashLayout/DashPages/AdForm2"));
const SocialTasks = lazy(() =>
  import("./component/DashLayout/DashPages/SoialTask")
);
const EarnForm = lazy(() =>
  import("./component/DashLayout/DashPages/EarnForm")
);
const EarnForm2 = lazy(() =>
  import("./component/DashLayout/DashPages/EarnForm2")
);
const PerformTask = lazy(() =>
  import("./component/DashLayout/DashPages/PerformTask")
);
const PerformTask2 = lazy(() =>
  import("./component/DashLayout/DashPages/PerformTask2")
);
const Vote = lazy(() => import("./component/DashLayout/DashPages/Vote/Vote"));
const VoteDetails = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/VoteDetails")
);
const VoteList = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/VoterList")
);
const VoteCandidate = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/VoteCandidate")
);
const VoteSuccess = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/VoteSuccess")
);
const ViewVote = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/ViewVote")
);
const VotesCont = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/Sub-vote/VotesContainer")
);
const CreateVote = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/Sub-vote/CreateVote")
);
const PublishedContest = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/Sub-vote/PublishedContest")
);

const SellForm = lazy(() =>
  import("./component/DashLayout/DashPages/SellForm")
);
const Verify = lazy(() => import("./pages/Verify"));

const ULF = lazy(() => {
  import("./component/DashLayout/DashPages/UploadForm");
});

const ChangePassword = lazy(() => {
  import("./pages/Password");
});

const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

const CreateEvents = lazy(() =>
  import("./component/DashLayout/DashPages/CreateEvents")
);
const CreateTickets = lazy(() =>
  import("./component/DashLayout/DashPages/CreateTickets")
);
const EventDetails = lazy(() =>
  import("./component/DashLayout/DashPages/EventDetails")
);
const TicketDetails = lazy(() =>
  import("./component/DashLayout/DashPages/TicketDetails")
);
const Events = lazy(() => import("./component/DashLayout/DashPages/Events"));
const EventDetailsFront = lazy(() =>
  import("./component/DashLayout/DashPages/EventDetailsFront")
);
const EventCheckout = lazy(() =>
  import("./component/DashLayout/DashPages/EventCheckout")
);
const TicketDetailsFront = lazy(() =>
  import("./component/DashLayout/DashPages/TicketDetailsFront")
);
const EventOrder = lazy(() =>
  import("./component/DashLayout/DashPages/EventsOrder")
);
const EditTickets = lazy(() =>
  import("./component/DashLayout/DashPages/EditTicket")
);
const EventSendEmail = lazy(() =>
  import("./component/DashLayout/DashPages/EventSendEmail")
);

const Result = lazy(() =>
  import("./component/DashLayout/DashPages/Vote/Result/Result")
);

const override = {
  display: "flex",
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
  height: "100vh", // Set the height of the container to the full viewport height
  borderColor: "#040073",
};

function App() {
  const [color] = useState("#040073");
  const { isLoading } = useMyContext();
  const ClientLogged = sessionStorage.getItem("ClientLogged") || "false";

  return (
    <Router>
      <Suspense
        fallback={
          <div
            style={override}
            className="flex justify-center items-center h-screen"
          >
            <ClimbingBoxLoader
              color={color}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
              className="flex justify-center items-center"
            />
          </div>
        }
      >
        {isLoading ? (
          <div className="flex justify-center items-center h-screen">
            <ClimbingBoxLoader
              color={color}
              size={40}
              loading={isLoading}
              aria-label="Loading Spinner"
              data-testid="loader"
              className="flex justify-center items-center"
            />
          </div>
        ) : (
          <Routes>
            {ClientLogged === "false" ? (
              <>
                {/* public Routes */}

                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register/:code?" element={<Register1 />} />
                <Route path="/register2/:regMail" element={<Register2 />} />
                <Route path="/register3/:regMail" element={<Register3 />} />
                <Route path="/register4/:regMail" element={<Register4 />} />
                {/* Redirect to the login page if no other route matches */}
                {/* <Route index element={<Navigate to="/login" />} /> */}
                <Route path="/earn" element={<Earn />} />
                <Route path="/advert" element={<Advert />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route
                  path="/marketProduct/:itemId"
                  element={<MarketItemPage2 />}
                />
                <Route path="/support" element={<Support />} />
                <Route path="/task" element={<TaskPage />} />
                <Route path="/facebookad" element={<FacebookAd />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/change-password" element={<Password />} />
                <Route path="/verify/:token/:email" element={<Verify />} />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route path="/eula" element={<EULA />} />
                <Route path="/refundpolicy" element={<RefundPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/about" element={<AboutPage />} />
              </>
            ) : (
              <>
                {/* Private Routes */}

                {/* event Routes */}

                <Route path="/events" element={<Events />} />
                <Route
                  path="events/event-details/:id"
                  element={<EventDetailsFront />}
                />
                <Route
                  path="/events/:id/checkout"
                  element={<EventCheckout />}
                />
                <Route
                  path="events/ticket-details/:id"
                  element={<TicketDetailsFront />}
                />
                <Route path="/events/:id" element={<EventDetails />} />
                <Route
                  path="/events/:id/my-ticket"
                  element={<TicketDetails />}
                />
                <Route
                  path="/events/create-events/:step?/:id?"
                  element={<CreateEvents />}
                />
                <Route
                  path="/events/create-tickets/:eventId"
                  element={<CreateTickets />}
                />
                <Route
                  path="/events/edit-tickets/:id"
                  element={<EditTickets />}
                />
                <Route path="/events/my-order" element={<EventOrder />} />
                <Route path="/events/:id/mail" element={<EventSendEmail />} />

                {/* ********************* */}

                <Route path="/">
                  <Route index element={<Dash />} />
                  <Route path="wallet" element={<DashWallet />} />
                  <Route path="tasks" element={<Tasks />} />
                  <Route
                    path="taskdelivery/:itemId"
                    element={<TaskDelivery />}
                  />
                  <Route
                    path="taskdelivery/eng/:itemId"
                    element={<TaskDeliveryEng />}
                  />

                  <Route path="earn" element={<DashEarn />} />
                  <Route path="earn/adv/:platform" element={<EarnForm />} />
                  <Route
                    path="earn/adv/perform-task/:taskId"
                    element={<PerformTask />}
                  />
                  <Route
                    path="earn/eng/perform-task/:taskId"
                    element={<PerformTask2 />}
                  />
                  <Route path="earn/eng/:platform" element={<EarnForm2 />} />
                  <Route path="social/buy/pricing" element={<Advertise2 />} />
                  <Route
                    path="/social/buy/adv/:platform"
                    element={<AdForm />}
                  />
                  <Route
                    path="/social/buy/eng/:platform"
                    element={<AdForm2 />}
                  />
                  <Route
                    path="/social/buy/feng/:platform"
                    element={<AdForm3 />}
                  />
                  <Route
                    path="/social/buy/fake/:platform"
                    element={<AdformFake />}
                  />
                  {/* <Route path="/social/tasks" element={<SocialTasks />} /> */}
                  <Route path="market/sell" element={<Advertise1 />} />
                  <Route path="market/sell/product" element={<SellForm />} />
                  <Route
                    path="market/upload/:product"
                    element={<UploadForm />}
                  />
                  <Route path="/market" element={<DashMarket />} />
                  <Route path="/my-product" element={<DashMyProdut />} />
                  <Route
                    path="/marketProduct/:itemId"
                    element={<MarketItemPage />}
                  />
                  <Route path="/vote" element={<Vote />} />
                  <Route
                    // path="/vote-details/:voteType/:id"
                    path="/vote/details/:id"
                    element={<VoteDetails />}
                  />
                  <Route path="/vote/list/:id" element={<VoteList />} />
                  <Route path="/vote/payment/:id" element={<VoteCandidate />} />
                  <Route path="/vote/purchased/:id" element={<VoteSuccess />} />
                  <Route path="/vote/success/view/:id" element={<ViewVote />} />
                  <Route path="/vote/view/votes" element={<VotesCont />} />
                  <Route path="/vote/create/vote" element={<CreateVote />} />
                  <Route
                    path="/published/contest/:id"
                    element={<PublishedContest />}
                  />

                  <Route path="/vote/result/:id" element={<Result />} />

                  {/* <Route path="/dashboard/chat" element={<DashChat />} /> */}
                  <Route path="settings" element={<Settings />} />
                  <Route path="orders" element={<DashOrder />} />
                  <Route path="/chat" element={<DashChat />} />
                  <Route path="/chat2" element={<Chat2 />} />
                  <Route path="notifications" element={<DashNotification />} />
                  <Route path="/become-member" element={<BecomeMember />} />
                  <Route path="/referrals" element={<ReferralList />} />
                  <Route path="/airtime" element={<Airtime />} />
                  <Route path="/data" element={<DataPurchase />} />

                  <Route path="/paytv" element={<PayTv />} />
                  <Route path="/electricity" element={<Electricity />} />
                  <Route path="/exam/waec" element={<ExamWaec />} />
                  <Route path="/exam/jamb" element={<ExamJamb />} />
                </Route>
              </>
            )}

            {/* Fallback route for 404 Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Suspense>
    </Router>
  );
}

export default App;
