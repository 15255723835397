import React from 'react';
import VisitorHeader from '../component/vistorsheader';
import Footer from "../component/footer";

const AboutPage = () => {
  return (
    <div>
        <VisitorHeader />
        <div className="flex flex-col justify-center items-center p-4 lg:p-10 mt-20">
      <div className="max-w-5xl w-full">
        <h2 className="text-3xl lg:text-4xl font-bold mb-6 text-center">ABOUT US</h2>
        <div className="bg-white shadow-lg rounded-lg p-6 lg:p-8">
          <h3 className="text-xl lg:text-2xl font-bold mb-4">Welcome to Buzzy.ng - Where Social Meets Commerce, and Every Interaction Is an Opportunity!</h3>
          <p className="mb-4">At Buzzy.ng, we are on a mission to redefine the social media landscape by creating a dynamic and engaging platform that seamlessly integrates social interactions, targeted advertising, and e-commerce. Our goal is to provide users, advertisers, and content creators with a unique space where every interaction matters.</p>
          <div>
            <p className="font-bold mb-2">Who We Are:</p>
            <p className="mb-4">Buzzy.ng is a social media marketing app designed to connect people, businesses, and marketers in a vibrant and mutually beneficial ecosystem. We believe in the power of meaningful connections, and we've built a platform that not only facilitates social engagement but also empowers users to monetize their activities.</p>
          </div>
          <div>
            <p className="font-bold mb-2">Key Features:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Targeted Advertising: Advertisers can leverage our platform for precise and effective targeted advertising, reaching their specific audience with ease.</li>
              <li>Monetization Opportunities: Users have the chance to turn their engagement into income, whether through content creation, product promotion, or participation in marketing campaigns.</li>
              <li>Integrated Marketplace: Buzzy.ng is not just a social platform; it's a thriving marketplace where users can showcase, sell, or resell products and services, creating a seamless blend of social interactions and e-commerce.</li>
            </ul>
          </div>
          <div>
            <p className="font-bold mb-2">Our Values:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Innovation: We strive to be at the forefront of innovation, continuously enhancing our platform to meet the evolving needs of our users and partners.</li>
              <li>Integrity: Trust is the foundation of our community. We operate with transparency, honesty, and a commitment to safeguarding user privacy.</li>
              <li>Collaboration: Buzzy.ng thrives on collaboration. We believe that by working together, we can create a platform that benefits everyone involved.</li>
            </ul>
          </div>
          <div>
            <p className="font-bold mb-2">Join the Buzzy.ng Community:</p>
            <p className="mb-4">Whether you're a user looking to connect with like-minded individuals, an advertiser aiming to reach a targeted audience, or a content creator seeking new opportunities, Buzzy.ng welcomes you to join our vibrant community. Every post, comment, and interaction contributes to the dynamic energy that defines Buzzy.ng.</p>
          </div>
          <div>
            <p className="font-bold mb-2">Get Started:</p>
            <p className="mb-4">Ready to experience the unique blend of social media, targeted advertising, and e-commerce? Join Buzzy.ng today and be a part of a community where your presence truly matters.</p>
          </div>
          <p className="font-bold">Thank you for choosing Buzzy.ng - Where Every Interaction Counts!</p>
          <p className="mt-2">With Regards,</p>
          <p>Muoma Obinna, CEO, Buzzy.ng</p>
        </div>
      </div>
    </div>
    <Footer />
    </div>
  );
};

export default AboutPage;
