import React from "react";
import VisitorsHeader from "../component/vistorsheader";
import SignUpImage from "../images/image-5.png";
import Bottom from "../component/bottom-section";
import Footer from "../component/footer";
import SignUpForm4 from "./SignUpForm4";
import { useParams } from "react-router-dom";

const Register4 = () => {
  let { regMail } = useParams();
  return (
    <div>
      <VisitorsHeader />
      <>
        <div className="flex py-4 my-3 flex-col lg:flex-row min-h-screen px-8 justify-between">
          {/* Left side with image */}
          <div className="lg:w-1/2 md:hidden hidden lg:block ">
            <img
              src={SignUpImage}
              alt="Sign Up"
              className="object-cover w-full h-full"
            />
          </div>

          {/* Right side with sign-up form and progress bar */}
          <div className="lg:w-1/2 flex items-center justify-center p-8 mt-4">
            <div className="max-w-md w-full">
              {/* Progress Bar */}

              <ul className="steps flex mb-4 w-full text-center ">
                <li className="step step-primary"></li>
                <li className="step step-primary"></li>
                <li className="step step-primary"></li>
                <li className="step step-primary"></li>
              </ul>
              <SignUpForm4 regMail={regMail} />
            </div>
          </div>
        </div>
      </>
      <Bottom />
      <Footer />
    </div>
  );
};

export default Register4;
