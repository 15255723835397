import React, { useState, useEffect } from "react";
import { FaCamera } from "react-icons/fa";
import userimg from "../images/user.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useMyContext } from "../context/MyContext";

const SignUpForm4 = ({ regMail }) => {
  const [image, setImage] = useState(null);
  const { email, isLoading, setEmail, setIsLoading } = useMyContext();
  const navigate = useNavigate(); // Initialize useHistory

  useEffect(() => {
    // Retrieve email from local storage
    const storedData = JSON.parse(localStorage.getItem("signupForm1State"));
    if (storedData && storedData.email) {
      setEmail(storedData.email);
    }
  }, []);

  const handleSkip = () => {
    setIsLoading(true); // Set loading state to true

    // Simulate loading with a setTimeout
    setTimeout(() => {
      setIsLoading(false); // Set loading state to false
      toast.success("Registered");
      navigate("/login"); // Navigate to /login
      localStorage.removeItem("signupStep");
    }, 2000); // Adjust the timeout duration (in milliseconds) as needed
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleImageUpload = async () => {
    try {
      setIsLoading(true); // Set loading state to true

      const formData = new FormData();
      formData.append("Image", image);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register/profileimage?email=${regMail}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const data = response.data;

      if (data.Access) {
        if (data.Uploaded) {
          toast.success("Profile picture uploaded successfully");
          navigate("/login");
          localStorage.removeItem("signupStep");
        } else {
          toast.error("Error uploading profile picture");
        }
      } else {
        toast.error(data.Error || "Unknown error");
      }
    } catch (error) {
      toast.error(error.response?.data?.Error || "Unknown error");
    } finally {
      setIsLoading(false); // Set loading state to false
    }
  };
  return (
    <div className="max-w-sm w-full">
      {/* Step 4: Upload Profile Picture */}
      <h2 className="text-3xl font-extrabold text-black">
        Add profile picture
      </h2>
      <p className="mt-2 text-sm text-black font-medium">
        You are almost done setting up your account.
      </p>
      <p className="text-sm text-gray-600">
        Simply upload a profile picture to enable people to recognize and
        connect with you later.
      </p>
      {/* ... your form inputs and logic */}
      <div className="mt-6 mb-4 flex items-center justify-center">
        <div className="relative">
          <img
            src={image ? URL.createObjectURL(image) : userimg}
            alt="profile"
            className="w-32 h-32 rounded-full object-cover"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="image-upload"
          />
          <label
            htmlFor="image-upload"
            className="absolute bottom-0 right-0 p-2 text-blue-700 rounded-full cursor-pointer"
          >
            <FaCamera />
          </label>
        </div>
      </div>
      <div className="mt-6">
        <button
          type="button"
          onClick={handleImageUpload}
          className="w-full py-2 px-4 border rounded-md text-white hover:bg-blue-800 focus:outline-none focus:border-blue-700"
        >
          Continue
        </button>
      </div>
      <div className="mt-2">
        <button
          type="button"
          onClick={handleSkip}
          className="w-full py-2 px-4 rounded-md text-blue-800 hover:text-blue-500 focus:outline-none"
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default SignUpForm4;
