import React from 'react';
import facebook from "../images/Facebook.png";
const FacebookEarn = () => {
    return ( 
        <div className='home-content py-2 px-5'>
            <div>
            <div className='flex justify-center my-3 mx-auto'>
                    <div className="flex flex-col mr-4">
                        <img src={facebook} alt='facebook-logo' className='h-[80px] w-[80px] my-2' />
                        <button className='px-4 py-1 font-normal rounded text-black border border-[#213481] text-center w-full'>Select</button>
                    </div>
                    <div className="right">
                        <h2 className='text-xl text-black font-semibold my-1'>Post Adverts on Facebook</h2>
                        <span className='text-sm text-black font-semibold'>Earnings: EDO100 per Facebook Advert Post</span>
                        <div className='h-1 w-full my-2 bg-gray-400'></div>
                        <div className='flex flex-col'>
                        <span className='text-sm font-medium text-black'>Share advertisements from different businesses and top brands on your Facebook Personal Page and earn EDO100 for each advert post. The more </span>
                        <span className='text-sm font-medium text-black'>you share, the higher your earnings. Please ensure that your Facebook account has at least 1000 active friends or followers to qualify for this task.</span>
                        </div>
                        <div className='flex my-2'><h2 className='text-sm text-black font-semibold mr-2'>Platform:</h2><img src={facebook} alt='facebook-logo' className='h-6 w-6' /></div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default FacebookEarn;