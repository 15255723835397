import React, { useState, useEffect } from "react";
import axios from "axios";
import io from "socket.io-client";

const Chat2 = ({ username }) => {
  const [socket, setSocket] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const [activeUsers, setActiveUsers] = useState([]);
  const [messages, setMessages] = useState([]);
  const [showChatList, setShowChatList] = useState(true);

  const userdata = sessionStorage.getItem("userDataE");
  const UD = userdata ? JSON.parse(userdata) : [];
  const usersUsername = UD?.User?.Username;

  useEffect(() => {
    const newSocket = io(`${process.env.REACT_APP_API_URL}`);
    setSocket(newSocket);

    newSocket.on("connect", () => {
      console.log("Connected to server");
    });

    newSocket.on("rooms", (roomList) => {
      console.log("Received room list:", roomList);
      setRooms(roomList);
      if (roomList.length > 0) {
        setCurrentRoom(roomList[0]._id); // Set the first room as the default
      }
    });

    newSocket.on("activeUsers", (users) => {
      console.log("Active users:", users);
      setActiveUsers(users);
    });

    const fetchChatRooms = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/marketplace/chatoperation/rooms`,
          {
            headers: {
              Authorization: sessionStorage.getItem("authToken"),
            },
          }
        );

        console.log("Fetched chat rooms:", response.data);

        if (response.data.Access) {
          setRooms(response.data.Rooms);
        }
      } catch (error) {
        console.error("Error fetching chat rooms:", error);
      }
    };

    fetchChatRooms();

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleRoomChange = async (room) => {
    setCurrentRoom(room._id);

    // Fetch chats in the selected room
    await fetchChatsInRoom(room._id);

    // Join room via socket
    if (socket) {
      socket.emit("joinRoom", room._id);
    }

    // Always show chat list on desktop screens
    setShowChatList(true);
  };

  const fetchChatsInRoom = async (roomId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/chatoperation/allchat/${roomId}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      console.log("Fetched chats in room:", response.data);

      if (response.data.Access) {
        setMessages(response.data.Chats);
      }
    } catch (error) {
      console.error("Error fetching chats in room:", error);
    }
  };

  const handleClearChats = async () => {
    if (!currentRoom) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/chatoperation/clearchat/${currentRoom}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      console.log("Clear chats response:", response.data);

      if (response.data.Access) {
        // Clear chat messages in UI
        setMessages([]);
      }
    } catch (error) {
      console.error("Error clearing chats:", error);
    }
  };

  const handleDeleteChats = async () => {
    if (!currentRoom) return;

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/marketplace/chatoperation/deletechat/${currentRoom}`,
        {
          headers: {
            Authorization: sessionStorage.getItem("authToken"),
          },
        }
      );

      console.log("Delete chats response:", response.data);

      if (response.data.Access) {
        // Clear chat messages in UI
        setMessages([]);
      }
    } catch (error) {
      console.error("Error deleting chats:", error);
    }
  };

  const handleMessageSend = (messageData) => {
    // Implement your message sending logic here
    console.log("Sending message:", messageData);
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar for room selection (Always visible on desktop) */}
      <div className="w-1/4 bg-gray-200 p-4 overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4">Messages</h2>
        <ul>
          {rooms.map((room) => (
            <li
              key={room._id}
              className="cursor-pointer p-2 mb-2 rounded bg-blue-200 font-semibold"
              onClick={() => handleRoomChange(room)}
            >
              <img
                src={room.ChatLogo}
                alt="Chat Logo"
                className="w-8 h-8 mr-2"
              />
              {room.Title}
            </li>
          ))}
        </ul>
        <div className="mt-4">
          {/* Dropdown for room operations */}
          <select
            onChange={(e) =>
              e.target.value === "clear"
                ? handleClearChats()
                : handleDeleteChats()
            }
          >
            <option value="">Room Actions</option>
            <option value="clear">Clear Chats</option>
            <option value="delete">Delete Chats</option>
          </select>
        </div>
      </div>

      {/* Main chat area */}
      <div className="flex-1 bg-white">
        <div className="p-4 border-b border-gray-300 flex items-center justify-between">
          <button
            className="text-blue-500"
            onClick={() => setShowChatList(true)}
          >
            &lt; Back
          </button>
          <h2 className="text-xl font-semibold">{currentRoom}</h2>
          <p>Active Users: {activeUsers.join(", ")}</p>
        </div>
        <div className="overflow-y-auto p-4">
          {/* Display messages here */}
          {messages.length === 0 ? (
            <p className="text-center">No messages yet</p>
          ) : (
            messages.map((msg) => (
              <div
                key={msg.ChatId}
                className={`flex flex-col mb-4 chat  ${
                  msg.From === usersUsername ? "chat-end" : "chat-start"
                }`}
              >
                <p className="font-semibold">{msg.From}</p>
                {msg.Body && (
                  <p
                    className={`chat-bubble  ${
                      msg.From === usersUsername ? "" : "chat-bubble-primary"
                    }`}
                  >
                    {msg.Body}
                  </p>
                )}
                {msg.ImageLink && (
                  <img
                    src={msg.ImageLink}
                    alt="Image"
                    className="max-w-full h-auto"
                  />
                )}
                {msg.VideoLink && (
                  <div className="video-container">
                    <iframe
                      src={msg.VideoLink}
                      title="Video"
                      className="w-full h-48"
                    />
                  </div>
                )}
              </div>
            ))
          )}
        </div>
        {/* Chat input component */}
        <ChatInput username={username} onSend={handleMessageSend} />
      </div>
    </div>
  );
};

const ChatInput = ({ username, onSend }) => {
  const [inputData, setInputData] = useState({
    Body: "",
    ImageLink: "",
    VideoLink: "",
  });

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputData.Body || inputData.ImageLink || inputData.VideoLink) {
      const messageData = {
        From: username,
        ...inputData,
      };
      onSend(messageData);
      setInputData({ Body: "", ImageLink: "", VideoLink: "" });
    }
  };

  return (
    <form className="flex p-2" onSubmit={handleSubmit}>
      <input
        type="text"
        name="Body"
        value={inputData.Body}
        onChange={handleChange}
        placeholder="Type a message..."
        className="flex-1 mr-2 p-2 rounded border border-gray-300 focus:outline-none"
      />
      <button
        type="submit"
        className="px-4 py-2 bg-blue-500 text-white rounded"
      >
        Send
      </button>
    </form>
  );
};

export default Chat2;
