import React from 'react';
import VisitorHeader from '../component/vistorsheader';
import Footer from "../component/footer";

const EULA = () => {
  return (
    <div>
      <VisitorHeader />
      <div className='py-4 lg:py-8 px-4 mt-20 max-w-5xl mx-auto bg-white shadow-lg rounded-lg'>
        <h2 className="text-3xl lg:text-4xl font-medium text-[#2d2d2d] mb-6 text-center">END USER LICENSE AGREEMENT (EULA)</h2>
        <p className="text-lg"><strong className="text-lg">Effective Date:</strong> February 7, 2025</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">Overview:</h3>
        <p className="text-lg">This End User License Agreement ("Agreement") is a legal agreement between you ("User") and Buzzy.ng ("Company"), governing your use of the Buzzy.ng mobile application and related services ("App"). By installing, accessing, or using the App, you agree to be bound by the terms of this Agreement. If you do not agree, do not install or use the App.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">1. License Grant:</h3>
        <p className="text-lg">Buzzy.ng grants you a limited, non-exclusive, non-transferable, and revocable license to use the App for personal and commercial purposes in accordance with this Agreement.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">2. Restrictions:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">You may not modify, reverse-engineer, decompile, or attempt to derive the source code of the App.</li>
          <li className="text-lg">You may not use the App for any illegal, abusive, or unauthorized purpose.</li>
          <li className="text-lg">You may not rent, lease, sublicense, or distribute the App.</li>
          <li className="text-lg">You must not violate any applicable laws, regulations, or third-party rights while using the App.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">3. Account Registration & Security:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">Users must provide accurate and up-to-date information during registration.</li>
          <li className="text-lg">Users are responsible for maintaining the confidentiality of their accounts and passwords.</li>
          <li className="text-lg">The Company reserves the right to suspend or terminate accounts suspected of fraudulent or abusive activities.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">4. Content Submission:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">Users are solely responsible for the content they upload, including advertisements, products, and engagement tasks.</li>
          <li className="text-lg">Content must not be abusive, fraudulent, misleading, or violate the Company’s terms and conditions.</li>
          <li className="text-lg">Buzzy.ng reserves the right to review, remove, or reject content that violates its policies.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">5. Transactions & Payment Processing:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">Buzzy.ng facilitates transactions through third-party payment providers.</li>
          <li className="text-lg">Users acknowledge that Buzzy.ng is not responsible for delays, failures, or errors in payment processing.</li>
          <li className="text-lg">Any disputes regarding payments should be directed to the respective payment provider.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">6. Privacy & Data Collection:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">Buzzy.ng collects and processes user data in accordance with its Privacy Policy.</li>
          <li className="text-lg">Users have the right to delete their accounts and request the removal of their data.</li>
          <li className="text-lg">Buzzy.ng does not sell user data to third parties.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">7. Updates & Modifications:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">The Company may update the App to improve functionality or comply with legal requirements.</li>
          <li className="text-lg">Users agree to receive automatic updates unless they disable such features on their devices.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">8. Termination:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">The Company reserves the right to terminate this Agreement and restrict user access for violations of this Agreement.</li>
          <li className="text-lg">Users may discontinue using the App at any time by deleting their account.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">9. Disclaimer of Warranties:</h3>
        <p className="text-lg">The App is provided "as is" without warranties of any kind. The Company does not guarantee uninterrupted or error-free performance.</p>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">10. Limitation of Liability:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">The Company shall not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use the App.</li>
          <li className="text-lg">The maximum liability of the Company shall not exceed the amount paid (if any) by the user for the App’s services.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">11. Governing Law & Dispute Resolution:</h3>
        <ul className="list-disc pl-6">
          <li className="text-lg">This Agreement shall be governed by the laws of Nigeria.</li>
          <li className="text-lg">Disputes shall be resolved through arbitration or mediation before pursuing legal action.</li>
        </ul>

        <h3 className="text-xl lg:text-2xl font-medium text-[#2d2d2d] mt-8 mb-4">12. Contact Information:</h3>
        <p className="text-lg">For questions or concerns regarding this Agreement, contact us at <a href="mailto:support@buzzy.ng">support@buzzy.ng</a>.</p>

        <p className="text-lg mt-4">By using Buzzy.ng, you acknowledge that you have read, understood, and agree to abide by this End User License Agreement.</p>
      </div>
      <Footer />
    </div>
  );
};

export default EULA;
