import React, { useState, useEffect } from "react";
import { FaUser, FaBars, FaTimes } from "react-icons/fa";
import logo from "../images/buzzy-logo.png";
import { Link } from "react-router-dom";

const VisitorsHeader = () => {
  const [nav, setNav] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleClick = () => setNav(!nav);
  const handleClose = () => setNav(false);

  useEffect(() => {
    const handleResize = () => {
      const newWindowWidth = window.innerWidth;
      setWindowWidth(newWindowWidth);
      if (newWindowWidth <= 768) {
        setNav(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const links = [
    { to: "/", label: "Home" },
    { to: "/earn", label: "Earn" },
    { to: "/advert", label: "Advertise" },
    { to: "/marketplace", label: "Marketplace" },
    { to: "/support", label: "Support" },
  ];

  return (
    <div className="w-full h-[10vh] top-0 bg-white fixed drop-shadow-sm z-50">
      <div className="md:w-[92%] h-full mx-auto px-2 flex justify-between items-center">
        <div className="flex items-center">
          <img src={logo} alt="Logo" className="w-15 h-16" />
        </div>

        {/* hide this section when it goes to mobile  */}

        {windowWidth > 768 && (
          <div className="flex flex-col items-center md:flex-row md:space-x-8">
            {links.map((link) => (
              <Link
                key={link.to}
                to={link.to}
                className="text-[20] font-light focus:outline-none hover:font-normal cursor-pointer hover:text-[#213481] transition duration-300"
              >
                {link.label}
              </Link>
            ))}
          </div>
        )}

        <div className="hidden md:flex items-center space-x-4 sm:hidden">
          <Link
            to="/register"
            onClick={() => localStorage.removeItem("signupStep")}
          >
            <button className="text-black px-4 py-2 cursor-pointer text-[20] hover:text-[#213481] transition duration-300 font-normal rounded">
              Register
            </button>
          </Link>
          <Link to="/login">
            <button className="login-bg flex justify-evenly cursor-pointer hover:bg-blue-800 text-white px-4 py-2 rounded">
              <FaUser className="text-lg pt-1 mr-1" />
              Login
            </button>
          </Link>
        </div>

        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <FaBars className="w-6" /> : <FaTimes className="w-5" />}
        </div>
      </div>

      <div
        className={
          !nav
            ? "hidden"
            : "absolute bg-white w-full h-[80vh] drop-shadow-sm px-8 py-5"
        }
      >
        <ul>
          <li className="border-b-2 border-zinc-100 w-full text-center py-5 cursor-pointer hover:text-[#213481] transition duration-300">
            <Link onClick={handleClose} to="/" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className="border-b-2 border-zinc-100 w-full text-center py-5 cursor-pointer hover:text-[#213481] transition duration-300">
            <Link
              onClick={handleClose}
              to="/earn"
              smooth={true}
              offset={-200}
              duration={500}
            >
              Earn
            </Link>
          </li>
          <li className="border-b-2 border-zinc-100 w-full text-center py-5 cursor-pointer hover:text-[#213481] transition duration-300">
            <Link
              onClick={handleClose}
              to="/advert"
              smooth={true}
              offset={-50}
              duration={500}
            >
              Advertise
            </Link>
          </li>
          <li className="border-b-2 border-zinc-100 w-full text-center py-5 cursor-pointer hover:text-[#213481] transition duration-300">
            <Link
              onClick={handleClose}
              to="/marketplace"
              smooth={true}
              offset={-100}
              duration={500}
            >
              Marketplace
            </Link>
          </li>
          <li className="border-b-2 border-zinc-100 w-full text-center py-5 cursor-pointer hover:text-[#213481] transition duration-300">
            <Link
              onClick={handleClose}
              to="/support"
              smooth={true}
              offset={-50}
              duration={500}
            >
              Support
            </Link>
          </li>
          <Link
            to="/register"
            onClick={() => localStorage.removeItem("signupStep")}
          >
            <button className="text-black px-4 py-2 cursor-pointer text-[20] hover:text-[#213481] transition duration-300 font-normal rounded border border-[#213481] text-center w-full my-3">
              Register
            </button>
          </Link>

          <Link to="/login">
            <button className="login-bg flex justify-evenly cursor-pointer hover:bg-blue-800 text-white px-4 py-2 rounded w-full my-3">
              Login
            </button>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default VisitorsHeader;
